import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Buergschaft = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was du über die Bürgschaft beim Immobilienkauf wissen solltest" showCalc={false} />
            <Article>
                <p>
                    Oft kann man sich als junger Menschen noch kein{" "}
                    <Link to="/artikel/eigentum/" target="_blank" rel="noreferrer noopener">
                        Eigenheim
                    </Link>{" "}
                    leisten, weil man nicht ausreichend Eigenkapital angespart hat. Manchen wird dann von ihren Eltern
                    angeboten, die Bürgschaft zu übernehmen, damit es klappt. Vielleicht verdienen du und dein*e
                    Partner*in auch nicht gleich viel, in diesem Fall kann vielleicht auch die eine Person die
                    Bürgschaft für die andere übernehmen. Das ermöglicht einer Person, trotz schlechter Bonität, ein
                    eigenes Zuhause zu kaufen. Aber das Thema Bürgschaft ist nicht ganz so harmlos. Es gibt einiges zu
                    beachten, bevor man sich darauf einlässt. In diesem Artikel wollen wir dich darüber informieren.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist die Bürgschaft eigentlich genau?</h2>
                <p>
                    Ein Bürge stellt Sicher, dass die{" "}
                    <Link to="/artikel/schulden-erben/" target="_blank" rel="noreferrer noopener">
                        Schulden
                    </Link>{" "}
                    der Person, für die er oder sie bürgt, wieder zurückgezahlt werden. Dadurch verpflichtet sich die
                    bürgende Person für den Kredit, wenn die Person, die ihn aufgenommen hat, die Raten nicht mehr
                    zurückzahlen kann. Die{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditraten
                    </Link>{" "}
                    werden dann von der bürgenden Person übernommen. Das Kreditinstitut, also die Bank, überprüft
                    deshalb die Bonität der Person, welche die Bürgschaft übernimmt. In den meisten Fällen handelt es
                    sich bei der Bürgschaft um Immobilienkäufe oder Mieten, bei denen Eltern für ihre Kinder bürgen.
                    Sobald der Kredit allerdings getilgt ist, endet auch die Verpflichtung als Bürge. Man kann jedoch
                    auch einen eigenen festen Termin ausmachen, an dem die Bürgschaft enden soll. Was man dabei noch
                    festlegen kann, ist der Betrag, für den jemand bürgen möchte.
                </p>
                <p>
                    Wie das genau funktioniert? Es wird bei allen Arten der Bürgschaft vertraglich geregelt, wer für wen
                    wie lange und für welche Summe bürgt. Dabei handelt es sich um einen schriftlichen Vertrag zwischen
                    Gläubiger, Schuldner und Bürgen. Der Bürge ist ab Unterzeichnung des Vertrags daran gebunden, den
                    Kredit des Schuldner oder der Schuldnerin zu übernehmen, sollte diese(r) nicht mehr zahlen können.
                    Ob die bürgende Person auch wirklich zahlen kann, wird selbstverständlich vorher von der Bank
                    überprüft.
                </p>
                <hr />

                <h2>Gibt es unterschiedliche Arten von Bürgschaft?</h2>
                <p>
                    Ja, es gibt verschiedene Formen der Bürgschaft, die man kennen sollte, bevor man sich darauf
                    einlässt. Wir erklären sie kurz.
                </p>
                <p>
                    Zuallererst gibt es die tatsächlich sogenannte Gewöhnliche Bürgschaft: Bei dieser ist zu beachten,
                    dass der Gläubiger bereits erfolglos durch gerichtliche und außergerichtliche Einmahnungen gegenüber
                    dem Schuldner oder der Schuldnerin versucht haben muss, das Geld von dieser Person einzutreiben.
                    Erst wenn das geschehen ist, wird der Bürge herangezogen.
                </p>
                <p>
                    Dann gibt es die Haftung als Bürge und Zahler, welche der Bank naturgemäß am liebsten ist. Bei
                    dieser Form, kann der Bürge nämlich sofort herangezogen werden, sobald der Schuldner oder die
                    Schuldnerin ihre Kreditraten nicht zahlt. Bei dieser Art sind Bürgen Mitschuldner an der gesamten
                    Summe.
                </p>
                <p>
                    Es gibt außerdem die Solidargemeinschaft. Dabei bürgen Ehepartner im Zuge ihrer gemeinsamen
                    Immobilienfinanzierung für die dabei entstandenen Schulden.
                </p>
                <p>
                    Hast du schon von der Ausfallbürgschaft gehört? Diese kann eine Rettung für Ehepartner sein, die
                    Bürge und Zahler sind, weil die Ausfallbürgschaft im Falle einer Scheidung auch vor Gericht
                    beantragt werden kann. Das verringert das Risiko des Bürgen, weil er oder sie nur nach einer
                    erfolglosen Exekution beim Kreditnehmer, oder der Kreditnehmerin herangezogen werden darf. Außerdem
                    belastet es die{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    im Vergleich zu anderen Bürgschaften nur sehr gering, was hilfreich ist, wenn man selbst später eine
                    Immobilie kaufen möchte.
                </p>
                <p>
                    Außerdem gibt es noch befristete Bürgschaften, bei der vertraglich eine zeitliche Frist festgelegt
                    wird, nach der die Bürgschaft wieder ungültig wird bzw. erlischt. Und es gibt die
                    Höchstbetragsbürgschaft, bei der die Summe, für die eine Person bürgt, begrenzt wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Voraussetzungen gibt es bei der Bürgschaft?</h2>
                <p>
                    Bevor jemand für dich bürgen kann, oder du für jemand anderen, muss zuerst geklärt werden, ob ihr
                    die Voraussetzungen für eine Bürgschaft überhaupt erfüllt. Denn nicht jeder kann zum Bürge werden.
                </p>
                <p>
                    Die wichtigste Rolle bei der Ermittlung spielen die Eigenmittel und Einkommen der Person, die bürgen
                    möchte. Ob man von der Bank als Bürge akzeptiert wird, hängt davon ab, ob die Bank die Bonität als
                    ausreichend bewertet. Dafür wird ein regelmäßiges und ausreichend hohes Einkommen erwartet, um den
                    Kredit später auch wirklich zurückzahlen zu können. Wichtig ist hier auch, dass es keinen negativen
                    KSV-Eintrag gibt, denn diese werden von der Bank auch geprüft. Außerdem muss der Bürge volljährig
                    sein und ein Vertrauensverhältnis zum Kreditnehmer oder der Kreditnehmerin haben. Das ist
                    tatsächlich sehr wichtig, sonst wird der Bürge nicht akzeptiert. Für den schriftlichen Vertrag sind
                    eine Unterschrift und ein Einverständnis von Bürge, Kreditnehmer und Kreditgeber erforderlich.
                </p>
                <hr />

                <h2>Was vor einer Bürgschaft genau überprüft werden sollte</h2>
                <p>
                    Kann die Person, die bürgen möchte, tatsächlich in Zukunft zahlen? Vielleicht sieht es momentan
                    finanziell gut aus, aber wird das auch in sechs oder sieben Jahren der Fall sein? Man weiß
                    schließlich nicht, wann man als Bürge gebraucht wird und in welcher Situation man sich in mehreren
                    Jahren dann befinden wird. Bürgen ist eine ernste Sache, denn wenn der Bürge zahlungsunfähig ist,
                    kann es bei fehlender Zahlung bis zu einer Zwangsvollstreckung kommen. Wenn die bürgende Person
                    Insolvenz anmeldet, kann sie jedoch auch das Schuldenregulierungsverfahren mit einer
                    Restschuldbefreiung in Anspruch nehmen. Wenn keine Minderung oder Befreiung der Schulden ermöglicht
                    werden, geht die Summe in die Insolvenzmasse mit hinein.
                </p>
                <p>
                    Das heißt: es ist essentiell, dass man vor der Bürgschaft wirklich sicher ist, dass die Kreditsumme
                    auch in Zukunft von der bürgenden Person gestemmt werden kann. Die Folgen bei Zahlungsunfähigkeit
                    sind für diese Person nämlich ziemlich hart.
                </p>
                <p>
                    Meistens fordern Banken, dass ein Bürge die Art der Bürgschaft als Bürge und Zahler, die wir oben
                    erklärt haben, unterschreibt. Dann kann er oder sie sofort zur Zahlung herangezogen werden, wenn der
                    Schuldner nicht zahlt. Man kann also nicht oft genug betonen, dass man als Bürge finanziell sehr gut
                    dastehen sollte, bevor man so etwas unterschreibt. Eine Bürgschaft sollte man sich vorher wirklich
                    sehr gut überlegen.
                </p>
                <hr />

                <h2>Was du also beachten solltest, bevor du eine Bürgschaft übernimmst</h2>
                <p>
                    Bevor du dich dazu verpflichtest, für jemanden die Bürgschaft zu übernehmen, solltest du dir alle
                    Vor- und Nachteile gut durch den Kopf gehen lassen. Denn es kann immer ernst werden und dann ist der
                    Betrag, für den du bürgst, einfach weg. Du solltest nur dann für jemand anderen haften, wenn du
                    selbst keinen Wunsch hast, einen Kredit aufzunehmen, oder nur einen sehr kleinen. Und du solltest
                    die den möglichen Verlust der Summe, der bei Nichtzahlung der Person, für die du bürgst, von dir
                    verlangt wird, auch wirklich leisten können.
                </p>
                <p>
                    Du solltest auf jeden Fall wissen, dass du nach bezahlter Schuld für den Schuldner, von ihm einen
                    Ersatz dafür verlangen kannst. Außerdem kannst du die Aufhebung der Bürgschaft fordern, sobald die
                    festgelegte Summe bezahlt wurde. Deshalb ein Tipp: Begrenze deine Haftung auf einen maximalen Betrag
                    und lass die Prolongationsklauseln unbedingt ersatzlos streichen.
                </p>
                <p>
                    Wenn die Verpflichtungen, auf die du dich eingelassen hast, in einem sehr krassen Missverhältnis zu
                    deiner finanziellen Leistungsfähigkeit stehen, kann ein Richter sie verringern oder auch ganz
                    erlassen. Das ist vom Konsumentenschutzgesetz vorgesehen, doch wird von Fall zu Fall individuell
                    bestimmt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"buergschaft"}></BreadcrumbList>
            <ArticleStructuredData
                page={"buergschaft"}
                heading={"Was du über die Bürgschaft beim Immobilienkauf wissen solltest"}
            />
        </Layout>
    );
};

export default Buergschaft;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.buergschaft", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
